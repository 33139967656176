function secondsToTimeConvert(sec) {
  const minutes = Math.floor(sec / 60)
  const seconds = parseFloat((sec - (minutes * 60)).toFixed(2))
  let result = ''
  result += (minutes < 10) ? `0${minutes}` : minutes
  result += ':'
  result += (seconds < 10) ? `0${seconds}` : seconds
  return result
}
// seconds -> to object (minutes, seconds)
function secondsToMinutesConvert(sec) {
  if (sec === null) {
    return {
      minutes: null,
      seconds: null,
    }
  }
  const minutes = Math.floor(+sec / 60)
  const seconds = +(sec - (minutes * 60)).toFixed(2)
  return {
    minutes,
    seconds,
  }
}
export { secondsToTimeConvert, secondsToMinutesConvert }
