<template>
  <story-video-time-code-styles>
    <div>
      <div class="row">
        <div class="col-7 lesson-text-edit">
          <span
            v-for="val in storyTextWithTimecodesArray"
            :key="val.id"
            :data-text-pos-from="val.textPosFrom"
            :data-is-text-node="val.timecodeIndex === null ? 1 : 0"
            class="value text-item-node"
          >
            <popover-value
              v-if="val.timecodeIndex !== null"
              :val="val"
              :editable="editable"
              @apply="time => changeTimecode(val.timecodeIndex, time.minutes, time.seconds)"
              @remove="removeTimecode(val.timecodeIndex)"
            />
            <span
              v-else
              v-text="val.text"
            />
          </span>
        </div>
      </div>
      <div
        v-if="changed"
        class="d-flex mt-1"
      >
        <b-button
          variant="primary"
          :disabled="loading"
          @click="save"
        >
          <feather-icon icon="SaveIcon" />
          Save
        </b-button>
        <b-button
          variant="outline-secondary"
          :disabled="loading"
          class="ml-1"
          @click="cancel"
        >
          Cancel
        </b-button>
      </div>
    </div>
  </story-video-time-code-styles>
</template>

<script>
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { BButton } from 'bootstrap-vue'
import PopoverValue from '@/views/Lesson/Item/Components/VideoTab/TextManager/StoryVideoTimecodes/PopoverValue.vue'
import { uniqueId } from 'lodash/util'
import toast from '@/mixins/toast'
import { secondsToMinutesConvert } from '@/utils/general'
import textManager from '@/mixins/textManager'
import { StoryVideoTimeCodeStyles } from '../../../../../styled'

export default {
  components: {
    BButton,
    PopoverValue,
    StoryVideoTimeCodeStyles,
  },
  mixins: [
    toast,
    textManager,
  ],
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    videoType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    changed: false,
    loading: false,
    selectionMode: false,
    timecodes: [],
  }),
  computed: {
    storyText() {
      return get(this.lesson, 'story.text', '')
    },
    editable() {
      return !this.lesson.published_at
    },
    storyTextWithTimecodesArray() {
      const text = this.storyText
      const newText = []
      let tmpText = ''
      let tmpTextPosFrom = null
      for (let index = 0; index < text.length; index += 1) {
        const timecodeIndex = findIndex(this.timecodes, { story_pos_from: index })

        const attachedTimecode = timecodeIndex !== -1 ? this.timecodes[timecodeIndex] : null
        let selectedInAttachedTimecode = false
        if (attachedTimecode && this.selectionFrom && this.selectionFrom >= attachedTimecode.story_pos_from && this.selectionFrom < attachedTimecode.story_pos_to) {
          selectedInAttachedTimecode = true
        }

        if (attachedTimecode && !selectedInAttachedTimecode) {
          if (tmpText !== '') {
            newText.push({
              id: uniqueId(),
              minutes: 0,
              seconds: 0,
              timecodeIndex: null,
              text: tmpText,
              textPosFrom: tmpTextPosFrom,
            })
            tmpText = ''
            tmpTextPosFrom = null
          }

          const time = this.secondsToMinutes(attachedTimecode.timecode)
          newText.push({
            id: uniqueId(),
            minutes: time.minutes,
            seconds: time.seconds,
            timecodeIndex,
            text: text.substr(attachedTimecode.story_pos_from, attachedTimecode.story_pos_to - attachedTimecode.story_pos_from),
            textPosFrom: attachedTimecode.story_pos_from,
          })
          index = attachedTimecode.story_pos_to - 1
        } else {
          tmpText += text[index]
          if (tmpTextPosFrom === null) {
            tmpTextPosFrom = index
          }
        }
      }
      if (tmpText !== '') {
        newText.push({
          id: uniqueId(),
          minutes: 0,
          seconds: 0,
          timecodeIndex: null,
          text: tmpText,
          textPosFrom: tmpTextPosFrom,
        })
      }
      return newText
    },
  },
  mounted() {
    window.addEventListener('mouseup', this.mouseupHandler)
    this.prepareLessonTimecodes()
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.mouseupHandler)
    if (this.changed && window.confirm('Editor will be closed. Do you want to save changes?')) {
      this.save()
    }
  },
  methods: {
    // EventListener MouseUp
    mouseupHandler() {
      if (!this.editable) {
        return
      }
      const selectedTextData = this.getSelectedTextData()
      if (!selectedTextData) {
        return
      }
      /*
      //const posAttr = 'data-text-pos-from'
      const selection = window.getSelection()
      let selectionString = selection.toString()
      let selectionFrom = null
      let selectionTo = null
      let leftNode = null
      if (!selection || !selectionString.trim()) {
        return
      }

      const getTextItemNode = node => {
        if (node.classList && node.classList.contains('text-item-node')) {
          return node
        }
        if (node.parentNode) {
          return getTextItemNode(node.parentNode)
        }
        return null
      }
      const anchorNode = getTextItemNode(selection.anchorNode)
      const focusNode = getTextItemNode(selection.focusNode)
      if (!anchorNode || !focusNode || !anchorNode.hasAttribute(posAttr) || !focusNode.hasAttribute(posAttr)) {
        return
      }

      const anchorPosFrom = +anchorNode.getAttribute(posAttr)
      const focusPosFrom = +focusNode.getAttribute(posAttr)
      if (anchorPosFrom === focusPosFrom) {
        selectionFrom = selection.anchorOffset < selection.focusOffset ? selection.anchorOffset : selection.focusOffset
        selectionTo = selectionFrom + selectionString.length
        leftNode = anchorNode
      } else if (anchorPosFrom < focusPosFrom) {
        selectionFrom = selection.anchorOffset
        selectionTo = selectionFrom + selectionString.length
        leftNode = anchorNode
      } else {
        selectionFrom = selection.focusOffset
        selectionTo = selectionFrom + selectionString.length
        leftNode = focusNode
      }

      // left spaces
      const leftTrimmedString = selectionString.trimLeft()
      if (leftTrimmedString.length < selectionString.length) {
        selectionFrom += selectionString.length - leftTrimmedString.length
        selectionString = leftTrimmedString
      }
      // right spaces
      const rightTrimmedString = selectionString.trimRight()
      if (rightTrimmedString.length < selectionString.length) {
        selectionTo -= selectionString.length - rightTrimmedString.length
        selectionString = rightTrimmedString
      }

      // text-pos-from add to selection pos
      const textPosFrom = +leftNode.getAttribute(posAttr)
      selectionFrom += textPosFrom
      selectionTo += textPosFrom
*/
      // this.addTimecode(selectionFrom, selectionTo)
      this.addTimecode(selectedTextData.addPos.from, selectedTextData.addPos.to)
    },
    // add
    addTimecode(strPosFrom, strPosTo) {
      this.timecodes = this.timecodes.filter(_ => _.timecode !== null)
      this.timecodes.push({
        story_pos_from: strPosFrom,
        story_pos_to: strPosTo,
        timecode: null,
      })
    },
    // remove
    removeTimecode(index) {
      this.timecodes.splice(index, 1)
    },
    // update timecode value
    changeTimecode(index, minutes, seconds) {
      this.changed = true
      this.timecodes[index].timecode = (+minutes) * 60 + (+seconds)
    },
    // Close
    cancel() {
      if (this.changed && window.confirm('Confirm to cancel changes')) {
        this.prepareLessonTimecodes()
        this.changed = false
      }
    },
    // Save
    save() {
      this.loading = true
      const data = {
        video_type: this.videoType,
        timecodes: this.timecodes.map(_ => ({
          timecode: _.timecode,
          story_pos_from: _.story_pos_from,
          story_pos_to: _.story_pos_to,
        })),
      }
      this.$store.dispatch('lesson/attachTimecodes', { id: this.lesson.id, data })
        .then(() => {
          this.changed = false
          this.$emit('lessonUpdated', this.lesson.id)
        })
        .catch(error => {
          this.$toastError(get(error, 'response.data.message', 'Error'), 'Save')
        })
        .finally(() => {
          this.loading = false
        })
    },
    // initial methods
    prepareLessonTimecodes() {
      this.timecodes = cloneDeep(get(this.lesson, `videos.${this.videoType}.timecodes`, []))
    },
    secondsToMinutes: sec => secondsToMinutesConvert(sec),
  },
}
</script>

<style scoped lang="scss">
</style>
