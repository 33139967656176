<template>
  <span>
    <span
      :id="id"
      class="timecode-value cursor-pointer"
      v-text="val.text"
    />
    <b-popover
      ref="popover"
      :target="id"
      triggers="hover"
      placement="top"
      class="text-danger"
    >
      <div class="lesson-text-timecodes-modal__popover-timecode-value">
        <template v-if="editable">
          <div class="d-flex">
            <b-form-input
              v-model="minutes"
              type="number"
              min="0"
              max="59"
              class="minutes"
              placeholder="mm"
              @input="onChangeMinutes"
            />
            <b-form-input
              v-model="seconds"
              type="number"
              min="0"
              max="59.9"
              class="seconds ml-1"
              placeholder="ss"
              @input="onChangeSeconds"
            />
          </div>
          <div class="d-flex justify-content-between mt-1">
            <b-button
              :variant="changed ? 'primary' : 'secondary'"
              @click="apply"
            >
              <feather-icon
                icon="CheckIcon"
                size="12"
              />
            </b-button>
            <b-button
              variant="danger"
              @click="remove"
            >
              <feather-icon
                icon="Trash2Icon"
                size="12"
              />
            </b-button>
          </div>
        </template>
        <template v-else>
          {{ minutes }}m {{ seconds }}s
        </template>
      </div>
    </b-popover>
  </span>
</template>

<script>
import {
  BButton, BPopover, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BPopover,
    BFormInput,
  },
  props: {
    val: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    id: Math.random().toString(36).substr(2, 9),
    minutes: null,
    seconds: null,
    changed: false,
  }),
  mounted() {
    this.minutes = this.val.minutes
    this.seconds = this.val.seconds
  },
  methods: {
    onChangeMinutes() {
      if (this.minutes && this.minutes < 0) {
        this.minutes = 0
      }
      this.changed = true
    },
    onChangeSeconds() {
      if (this.seconds && (this.seconds < 0 || this.seconds > 59.999)) {
        this.seconds = 0
      }
      this.changed = true
    },
    apply() {
      this.$nextTick(() => {
        this.$emit('apply', {
          minutes: this.minutes,
          seconds: this.seconds,
        })
        this.$refs.popover.$emit('close')
        this.changed = false
      })
    },
    remove() {
      this.$refs.popover.$emit('close')
      setTimeout(() => this.$emit('remove'), 300)
    },
  },
}
</script>

<style scoped lang="scss">
</style>
