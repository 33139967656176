var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('story-dictionary-styles',[_c('div',{class:{
      'dictionary-new-highlight': _vm.dictionaryNewHighlight,
      'dictionary-used-once-highlight': _vm.dictionaryUsedOnceHighlight,
      'dictionary-used-twice-highlight': _vm.dictionaryUsedTwiceHighlight,
      'dictionary-used-more-highlight': _vm.dictionaryUsedMoreHighlight,
    }},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-7 lesson-text-edit"},[_vm._l((_vm.storyTextWithDictionaryArray),function(val){return _c('span',{key:val.id,staticClass:"value text-item-node",attrs:{"data-text-pos-from":val.textPosFrom,"data-is-text-node":val.dictionaryItem === null ? 1 : 0}},[(val.dictionaryItem !== null)?[_c('span',{staticClass:"dict-value",class:{
                'dictionary-new-highlight-color': _vm.dictionaryIsNew.includes(val.dictionaryItem.id),
                'dictionary-used-once-highlight-color': _vm.dictionaryIsUsedOnce.includes(val.dictionaryItem.id),
                'dictionary-used-twice-highlight-color': _vm.dictionaryIsUsedTwice.includes(val.dictionaryItem.id),
                'dictionary-used-more-highlight-color': _vm.dictionaryIsUsedMore.includes(val.dictionaryItem.id),
                'dictionary-focused-color': _vm.storyAttachedDictionaryFocusedIndex === val.attachedDictionaryIndex,
              },attrs:{"id":'text-' + val.id},domProps:{"textContent":_vm._s(val.text)},on:{"mouseover":function($event){_vm.storyAttachedDictionaryFocusedIndex = val.attachedDictionaryIndex},"mouseout":function($event){_vm.storyAttachedDictionaryFocusedIndex = null}}}),_c('b-popover',{ref:'popover-' + val.id,refInFor:true,attrs:{"target":'text-' + val.id,"triggers":"hover","placement":"top"}},[_c('div',{staticClass:"lesson-text-edit__popover-dict-value cursor-pointer",domProps:{"textContent":_vm._s(val.translate)},on:{"click":function () { return _vm.showDictionarySelectorItemModal(val.dictionaryItem, val.dictionaryTranslateId); }}}),(_vm.editable)?_c('feather-icon',{staticClass:"cursor-pointer position-absolute",style:({
                  'top': '5px',
                  'right': '5px',
                }),attrs:{"icon":"LinkIcon","size":"12","title":"Detach"},on:{"click":function($event){return _vm.popoverDetachClick(val)}}}):_vm._e()],1)]:[_c('span',{class:{
                selected: !!_vm.getSelectionStartsFromIndex(_vm.selection, val.textPosFrom),
              },attrs:{"id":'text-' + val.id},domProps:{"textContent":_vm._s(val.text)}})]],2)}),(_vm.changed)?_c('div',{staticClass:"d-flex align-items-center mt-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.save}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" Save ")],1),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-secondary","disabled":_vm.loading},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1):_vm._e(),(_vm.storyTextWithDictionaryArray.length)?_c('div',{staticClass:"d-flex align-items-center mt-2"},[_c('span',{staticClass:"font-small-3 mr-2 cursor-pointer",class:{
              'text-muted': !_vm.dictionaryNewHighlight,
              'dictionary-new-highlight-color': _vm.dictionaryNewHighlight,
            },on:{"click":_vm.dictionaryNewHighlightChange}},[_vm._v(" New: "+_vm._s(_vm.dictionaryIsNew.length)+" ")]),_c('span',{staticClass:"font-small-3 mr-2 cursor-pointer",class:{
              'text-muted': !_vm.dictionaryUsedOnceHighlight,
              'dictionary-used-once-highlight-color': _vm.dictionaryUsedOnceHighlight,
            },on:{"click":_vm.dictionaryUsedOnceHighlightChange}},[_c('span',{staticClass:"dictionary-used-number"},[_vm._v("1")]),_vm._v(" "+_vm._s(_vm.dictionaryIsUsedOnce.length)+" ")]),_c('span',{staticClass:"font-small-3 mr-2 cursor-pointer",class:{
              'text-muted': !_vm.dictionaryUsedTwiceHighlight,
              'dictionary-used-twice-highlight-color': _vm.dictionaryUsedTwiceHighlight,
            },on:{"click":_vm.dictionaryUsedTwiceHighlightChange}},[_c('span',{staticClass:"dictionary-used-number"},[_vm._v("2")]),_vm._v(" "+_vm._s(_vm.dictionaryIsUsedTwice.length)+" ")]),_c('span',{staticClass:"font-small-3 mr-2 cursor-pointer",class:{
              'text-muted': !_vm.dictionaryUsedMoreHighlight,
              'dictionary-used-more-highlight-color': _vm.dictionaryUsedMoreHighlight,
            },on:{"click":_vm.dictionaryUsedMoreHighlightChange}},[_c('span',{staticClass:"dictionary-used-number"},[_vm._v("3+")]),_vm._v(" "+_vm._s(_vm.dictionaryIsUsedMore.length)+" ")])]):_vm._e()],2),_c('dictionary-selector',{ref:"dictionarySelector",staticClass:"col-5",class:{ 'border-left': _vm.selection.length },attrs:{"native-language":_vm.lesson.native_lang,"foreign-language":_vm.lesson.foreign_lang},on:{"selected":_vm.dictionarySelector_onSelected,"closed":_vm.dictionarySelector_onClosed}}),_c('dictionary-selector-item-modal',{ref:"dictionarySelectorItemModal"})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }