export default {
  data: () => ({
    textNodeClass: 'text-item-node',
    textNodePosAttr: 'data-text-pos-from',
  }),
  methods: {
    getTextItemNode: node => {
      if (node.classList && node.classList.contains(this.textNodeClass)) {
        return node
      }
      if (node.parentNode) {
        return this.getTextItemNode(node.parentNode)
      }
      return null
    },
    getSelectionPos() {
      const selection = window.getSelection()
      let selectionString = selection.toString()
      let selectionFrom = null
      let selectionTo = null
      let leftNode = null
      if (!selection || !selectionString.trim()) {
        return null
      }
      const anchorNode = this.getTextItemNode(selection.anchorNode)
      const focusNode = this.getTextItemNode(selection.focusNode)
      if (!anchorNode || !focusNode || !anchorNode.hasAttribute(this.textNodePosAttr) || !focusNode.hasAttribute(this.textNodePosAttr)) {
        return null
      }

      const anchorPosFrom = +anchorNode.getAttribute(this.textNodePosAttr)
      const focusPosFrom = +focusNode.getAttribute(this.textNodePosAttr)
      if (anchorPosFrom === focusPosFrom) {
        selectionFrom = selection.anchorOffset < selection.focusOffset ? selection.anchorOffset : selection.focusOffset
        selectionTo = selectionFrom + selectionString.length
        leftNode = anchorNode
      } else if (anchorPosFrom < focusPosFrom) {
        selectionFrom = selection.anchorOffset
        selectionTo = selectionFrom + selectionString.length
        leftNode = anchorNode
      } else {
        selectionFrom = selection.focusOffset
        selectionTo = selectionFrom + selectionString.length
        leftNode = focusNode
      }

      // left spaces
      const leftTrimmedString = selectionString.trimLeft()
      if (leftTrimmedString.length < selectionString.length) {
        selectionFrom += selectionString.length - leftTrimmedString.length
        selectionString = leftTrimmedString
      }

      // right spaces
      const rightTrimmedString = selectionString.trimRight()
      if (rightTrimmedString.length < selectionString.length) {
        selectionTo -= selectionString.length - rightTrimmedString.length
        selectionString = rightTrimmedString
      }

      const textPosFrom = +leftNode.getAttribute(this.textNodePosAttr)
      return {
        from: selectionFrom + textPosFrom,
        to: selectionTo + textPosFrom,
      }
    },
  },
}
