<template>
  <test-grammar-styles>
    <div
      :class="{
        'dictionary-new-highlight': dictionaryNewHighlight,
        'dictionary-used-once-highlight': dictionaryUsedOnceHighlight,
        'dictionary-used-twice-highlight': dictionaryUsedTwiceHighlight,
        'dictionary-used-more-highlight': dictionaryUsedMoreHighlight,
      }"
    >
      <div class="row">
        <div class="col-7 lesson-text-edit">
          <span
            v-for="val in storyTextWithDictionaryArray"
            :key="val.id"
            :data-text-pos-from="val.textPosFrom"
            :data-is-text-node="val.dictionaryItem === null ? 1 : 0"
            class="value text-item-node"
          >
            <template v-if="val.dictionaryItem !== null">
              <span
                :id="'text-' + val.id"
                class="dict-value cursor-pointer"
                :class="{
                  'text-muted': !val.useInGrammarTest,
                  'border-0': !val.useInGrammarTest,
                  'dictionary-new-highlight-color': dictionaryIsNew.includes(val.dictionaryItem.id),
                  'dictionary-used-once-highlight-color': dictionaryIsUsedOnce.includes(val.dictionaryItem.id),
                  'dictionary-used-twice-highlight-color': dictionaryIsUsedTwice.includes(val.dictionaryItem.id),
                  'dictionary-used-more-highlight-color': dictionaryIsUsedMore.includes(val.dictionaryItem.id),
                  'dictionary-focused-color': storyDictionaryFocusedIndex === val.attachedDictionaryIndex,
                }"
                @mouseover="storyDictionaryFocusedIndex = val.attachedDictionaryIndex"
                @mouseout="storyDictionaryFocusedIndex = null"
                v-text="val.text"
              />
              <popover-value
                :ref="'popover-' + val.id"
                :target="'text-' + val.id"
                :editable="editable"
                :val="val"
                @apply="event => applyQuestion(val.attachedDictionaryIndex, event.useInTest, event.questionPos, event.answers)"
              />
            </template>
            <template v-else>
              <span
                :id="'text-' + val.id"
                v-text="val.text"
              />
            </template>
          </span>
        </div>
      </div>
      <div
        v-if="changed"
        class="d-flex align-items-center mt-1"
      >
        <b-button
          variant="primary"
          :disabled="loading"
          class="mr-1"
          @click="save"
        >
          <feather-icon icon="SaveIcon" />
          Save
        </b-button>
        <b-button
          variant="outline-secondary"
          :disabled="loading"
          class="mr-1"
          @click="cancel"
        >
          Cancel
        </b-button>
      </div>
      <div
        v-if="storyTextWithDictionaryArray.length"
        class="d-flex align-items-center mt-1"
      >
        <span
          class="font-small-3 mr-2 cursor-pointer"
          :class="{
            'text-muted': !dictionaryNewHighlight,
            'dictionary-new-highlight-color': dictionaryNewHighlight,
          }"
          @click="dictionaryNewHighlightChange"
        >
          New: {{ dictionaryIsNew.length }}
        </span>
        <span
          class="font-small-3 mr-2 cursor-pointer"
          :class="{
            'text-muted': !dictionaryUsedOnceHighlight,
            'dictionary-used-once-highlight-color': dictionaryUsedOnceHighlight,
          }"
          @click="dictionaryUsedOnceHighlightChange"
        >
          <span class="dictionary-used-number">1</span> {{ dictionaryIsUsedOnce.length }}
        </span>
        <span
          class="font-small-3 mr-2 cursor-pointer"
          :class="{
            'text-muted': !dictionaryUsedTwiceHighlight,
            'dictionary-used-twice-highlight-color': dictionaryUsedTwiceHighlight,
          }"
          @click="dictionaryUsedTwiceHighlightChange"
        >
          <span class="dictionary-used-number">2</span> {{ dictionaryIsUsedTwice.length }}
        </span>
        <span
          class="font-small-3 mr-2 cursor-pointer"
          :class="{
            'text-muted': !dictionaryUsedMoreHighlight,
            'dictionary-used-more-highlight-color': dictionaryUsedMoreHighlight,
          }"
          @click="dictionaryUsedMoreHighlightChange"
        >
          <span class="dictionary-used-number">3+</span> {{ dictionaryIsUsedMore.length }}
        </span>
      </div>
    </div>
  </test-grammar-styles>
</template>

<script>
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { BButton } from 'bootstrap-vue'
import { uniqueId } from 'lodash/util'
import { uniqBy } from 'lodash/array'
import toast from '@/mixins/toast'
import PopoverValue from '@/views/Lesson/Item/Components/TestsTab/TestGrammar/PopoverValue.vue'
import { TestGrammarStyles } from '../../../../styled'

export default {
  components: {
    BButton,
    PopoverValue,
    TestGrammarStyles,
  },
  mixins: [
    toast,
  ],
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    changed: false,
    loading: false,

    dictionaries: [],
    storyDictionaryFocusedIndex: null,

    // new / repeat
    previouslyUsedDictionaries: [],
    previouslyUsedDictionariesLoaded: false,
    dictionaryNewHighlight: false,
    dictionaryUsedOnceHighlight: false,
    dictionaryUsedTwiceHighlight: false,
    dictionaryUsedMoreHighlight: false,
    dictionaryIsNew: [],
    dictionaryIsUsedOnce: [],
    dictionaryIsUsedTwice: [],
    dictionaryIsUsedMore: [],
  }),
  computed: {
    dictionaryNewRepeatCount() {
      return true
    },
    editable() {
      return !this.lesson.published_at
    },
    storyText() {
      return get(this.lesson, 'story.text', '')
    },
    storyTextWithDictionaryArray() {
      const { storyText } = this
      const textArray = []
      const textArrayPush = (text, textPosFrom, dictionaryItem = null, attachedDictionaryIndex = null, customDictionaryData = null, useInGrammarTest = null, grammarTestQuestionPos = null, grammarTestAnswers = null) => {
        textArray.push({
          id: uniqueId(),
          dictionaryItem,
          attachedDictionaryIndex,
          text,
          textPosFrom,
          customDictionaryData,
          useInGrammarTest,
          grammarTestQuestionPos,
          grammarTestAnswers,
        })
      }

      // storyText chars (1: without dictionary, 2: not selected)
      let tmpText = ''
      let tmpTextPosFrom = null
      const textArrayPushTmpText = () => {
        if (tmpText !== '') {
          textArrayPush(tmpText, tmpTextPosFrom)
          tmpText = ''
          tmpTextPosFrom = null
        }
      }

      // loop chars
      for (let charIndex = 0; charIndex < storyText.length; charIndex += 1) {
        // search attachedDictionary
        let attachedDictionaryIndex = -1
        let attachedDictionaryPosIndex = null
        this.dictionaries.forEach((dictionaryItem, i) => {
          const selectionIndex = findIndex(dictionaryItem.story_pos, { from: charIndex })
          if (selectionIndex !== -1) {
            attachedDictionaryIndex = i
            attachedDictionaryPosIndex = selectionIndex
          }
        })
        const attachedDictionary = attachedDictionaryIndex !== -1 ? this.dictionaries[attachedDictionaryIndex] : null
        if (attachedDictionary) {
          textArrayPushTmpText()
          const pos = attachedDictionary.story_pos[attachedDictionaryPosIndex]
          textArrayPush(
            storyText.substr(pos.from, pos.to - pos.from),
            pos.from,
            attachedDictionary.dictionary_item,
            attachedDictionaryIndex,
            attachedDictionary.dictionary_custom_data || null,
            attachedDictionary.use_in_grammar_test,
            attachedDictionary.grammar_test_question_pos,
            attachedDictionary.grammar_test_answers,
          )
          // jump over indices
          charIndex = pos.to - 1
        } else {
          tmpText += storyText[charIndex]
          if (tmpTextPosFrom === null) {
            tmpTextPosFrom = charIndex
          }
        }
      }

      textArrayPushTmpText()
      return textArray
    },
  },
  mounted() {
    this.prepareStoryDictionary()
    this.loadPreviouslyUsedDictionaries()
  },
  beforeDestroy() {
    if (this.changed && window.confirm('Editor will be closed. Do you want to save changes?')) {
      this.save()
    }
  },
  methods: {

    applyQuestion(attachedDictionaryIndex, useInTest, questionPos, answers) {
      this.$set(this.dictionaries[attachedDictionaryIndex], 'use_in_grammar_test', useInTest)
      this.$set(this.dictionaries[attachedDictionaryIndex], 'grammar_test_question_pos', questionPos)
      this.$set(this.dictionaries[attachedDictionaryIndex], 'grammar_test_answers', answers)
      this.changed = true
      this.calcNewRepeatDictionary()
    },

    // Cancel changes
    cancel() {
      if (this.changed && window.confirm('Confirm to cancel changes')) {
        this.selection = []
        this.prepareStoryDictionary()
        this.changed = false
      }
    },

    // Save
    save() {
      this.loading = true
      const data = {
        story_dictionary: this.dictionaries.map(_ => ({
          dictionary_id: _.dictionary_item.id,
          dictionary_custom_data: _.dictionary_custom_data,
          story_pos: _.story_pos,
          use_in_translate_test: _.use_in_translate_test,
          use_in_grammar_test: _.use_in_grammar_test,
          grammar_test_question_pos: _.grammar_test_question_pos,
          grammar_test_answers: _.grammar_test_answers,
        })),
      }
      this.$store.dispatch('lesson/attachDictionary', { id: this.lesson.id, data })
        .then(() => {
          this.changed = false
          this.$emit('lessonUpdated', this.lesson.id)
          this.$toastDefault('Success', 'Save test')
        })
        .catch(error => {
          this.$toastError(get(error, 'response.data.message', 'Error'), 'Save')
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadPreviouslyUsedDictionaries() {
      this.previouslyUsedDictionaries = []
      const params = {
        lesson_id: this.lesson.id,
      }
      this.$store.dispatch('dictionary/listPreviouslyUsedForTest', params)
        .then(response => {
          this.previouslyUsedDictionaries = get(response, 'data.result')
        })
        .finally(() => {
          this.calcNewRepeatDictionary()
          this.previouslyUsedDictionariesLoaded = true
        })
    },
    calcNewRepeatDictionary() {
      const ids = uniqBy(
        this.dictionaries.filter(_ => _.use_in_grammar_test),
        _ => _.dictionary_item.id,
      ).map(_ => _.dictionary_item.id)

      this.dictionaryIsNew = []
      this.dictionaryIsUsedOnce = []
      this.dictionaryIsUsedTwice = []
      this.dictionaryIsUsedMore = []

      ids.forEach(id => {
        const index = this.previouslyUsedDictionaries.findIndex(_ => _.id === id)
        if (index === -1) {
          this.dictionaryIsNew.push(id)
        } else {
          const val = this.previouslyUsedDictionaries[index]
          switch (true) {
            case val.count === 1:
              this.dictionaryIsUsedOnce.push(id)
              break
            case val.count === 2:
              this.dictionaryIsUsedTwice.push(id)
              break
            case val.count > 2:
              this.dictionaryIsUsedMore.push(id)
              break
            default:
              break
          }
        }
      })
    },
    setDictionaryHighlight({
      usedNew = false,
      usedOnce = false,
      usedTwice = false,
      usedMore = false,
    }) {
      this.dictionaryNewHighlight = usedNew
      this.dictionaryUsedOnceHighlight = usedOnce
      this.dictionaryUsedTwiceHighlight = usedTwice
      this.dictionaryUsedMoreHighlight = usedMore
    },
    dictionaryNewHighlightChange() {
      this.setDictionaryHighlight({ usedNew: !this.dictionaryNewHighlight })
    },
    dictionaryUsedOnceHighlightChange() {
      this.setDictionaryHighlight({ usedOnce: !this.dictionaryUsedOnceHighlight })
    },
    dictionaryUsedTwiceHighlightChange() {
      this.setDictionaryHighlight({ usedTwice: !this.dictionaryUsedTwiceHighlight })
    },
    dictionaryUsedMoreHighlightChange() {
      this.setDictionaryHighlight({ usedMore: !this.dictionaryUsedMoreHighlight })
    },

    // initial methods
    prepareStoryDictionary() {
      this.dictionaries = cloneDeep(get(this.lesson, 'story.dictionaries', []))
    },
  },
}
</script>

<style scoped lang="scss">

</style>
