import indexesOf from '@/utils/string/indexesOf'
import cloneDeep from 'lodash/cloneDeep'

export default {
  data: () => ({

  }),
  methods: {
    getSelectedTextData: (posAttr = 'data-text-pos-from') => {
      const getTextItemNode = node => {
        if (node.classList && node.classList.contains('text-item-node')) {
          return node
        }
        if (node.parentNode) {
          return getTextItemNode(node.parentNode)
        }
        return null
      }

      const selection = window.getSelection()
      let selectionString = selection.toString()
      let selectionFrom = null
      let selectionTo = null
      let leftNode = null
      if (!selection || !selectionString.trim()) {
        return null
      }
      const anchorNode = getTextItemNode(selection.anchorNode)
      const focusNode = getTextItemNode(selection.focusNode)
      if (!anchorNode || !focusNode || !anchorNode.hasAttribute(posAttr) || !focusNode.hasAttribute(posAttr)) {
        return null
      }

      const anchorPosFrom = +anchorNode.getAttribute(posAttr)
      const focusPosFrom = +focusNode.getAttribute(posAttr)
      if (anchorPosFrom === focusPosFrom) {
        selectionFrom = selection.anchorOffset < selection.focusOffset ? selection.anchorOffset : selection.focusOffset
        selectionTo = selectionFrom + selectionString.length
        leftNode = anchorNode
      } else if (anchorPosFrom < focusPosFrom) {
        selectionFrom = selection.anchorOffset
        selectionTo = selectionFrom + selectionString.length
        leftNode = anchorNode
      } else {
        selectionFrom = selection.focusOffset
        selectionTo = selectionFrom + selectionString.length
        leftNode = focusNode
      }

      // left spaces
      const leftTrimmedString = selectionString.trimLeft()
      if (leftTrimmedString.length < selectionString.length) {
        selectionFrom += selectionString.length - leftTrimmedString.length
        selectionString = leftTrimmedString
      }
      // right spaces
      const rightTrimmedString = selectionString.trimRight()
      if (rightTrimmedString.length < selectionString.length) {
        selectionTo -= selectionString.length - rightTrimmedString.length
        selectionString = rightTrimmedString
      }

      const textPosFrom = +leftNode.getAttribute(posAttr)
      const addPos = {
        from: selectionFrom + textPosFrom,
        to: selectionTo + textPosFrom,
      }
      const addPosIndexes = []
      for (let i = addPos.from + 1; i < addPos.to; i += 1) {
        addPosIndexes.push(i)
      }
      return { addPos, addPosIndexes, selectionString }
    },
    findWordPositions(word, text) {
      const result = []
      const maxIndex = text.length - 1
      indexesOf(text.toLowerCase(), word.toLowerCase()).forEach(i => {
        const from = i
        const to = from + word.length
        const regex = /[A-Za-z0-9\-'`]/
        if (from !== 0 && regex.test(text[from - 1])) {
          return
        }
        if (to <= maxIndex && regex.test(text[to])) {
          return
        }
        result.push({
          from,
          to,
        })
      })
      return result
    },
    getSelectionStartsFromIndex(selection, index) {
      let result = null
      selection.forEach(pos => {
        if (pos.from === index) {
          result = cloneDeep(pos)
        }
      })
      return result
    },
  },
}
